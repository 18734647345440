export const listsName = [
  {
    name: 'Skills',
    route: 'skill',
  },
  {
    name: 'Energy level',
    route: 'energyLevel',
  },
  {
    name: 'Grades',
    route: 'grade',
  },
  {
    name: 'Relations',
    route: 'relation',
  },
]
