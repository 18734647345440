import React from 'react'
import { Button } from 'antd'
import AppLayout from 'components/layout/layout'
import { capitalizeFirstLetter } from 'helper/utility'
import { useParams } from 'react-router-dom'
import SkillsTable from './Tables/SkillsTable'
import { skillCreateStore } from 'state/listing/skillListing'
import { infoMessage } from 'api/global'
import SkillModal from './Modals/SkillModal'
import GradesTable from './Tables/GradesTable'
import { gradeStore } from 'state/listing/gradeListing'
import GradeModal from './Modals/GradeModal'
import RelationsTable from './Tables/RelationsTable'
import { relationStore } from 'state/listing/relationListing'
import RelationModal from './Modals/RelationModal'

const SpecificListingPage: React.FC = () => {
  const { id }: { id: string } = useParams()
  const { addSkillModal, setAddSkillModal, setSkillData } = skillCreateStore(
    store => ({
      addSkillModal: store.addSkillModal,
      setAddSkillModal: store.setAddSkillModal,
      setSkillData: store.setSkillData,
    }),
  )
  const { addGradeModal, setAddGradeModal, setGradeData } = gradeStore(
    store => ({
      addGradeModal: store.addGradeModal,
      setAddGradeModal: store.setAddGradeModal,
      setGradeData: store.setGradeData,
    }),
  )
  const { addRelationModal, setAddRelationModal, setRelationData } =
    relationStore(store => ({
      addRelationModal: store.addRelationModal,
      setAddRelationModal: store.setAddRelationModal,
      setRelationData: store.setRelationData,
    }))

  const handleNewClick = () => {
    switch (id) {
      case 'skill':
        setSkillData(null)
        setAddSkillModal(true)
        break
      case 'grade':
        setGradeData(null)
        setAddGradeModal(true)
        break
      case 'relation':
        setRelationData(null)
        setAddRelationModal(true)
        break
      default:
        infoMessage('Selected modal not found')
        break
    }
  }

  const handleComponent = () => {
    switch (id) {
      case 'skill':
        return <SkillsTable id={id} />
      case 'grade':
        return <GradesTable id={id} />
      case 'relation':
        return <RelationsTable id={id} />
    }
  }

  return (
    <AppLayout id={id}>
      <div className="container px-40px py-40px scroller overflow-auto-y">
        <div className="flex align-center justify-between">
          <h1 className="text-24 w-600">{capitalizeFirstLetter(id)}</h1>
          <div>
            <Button className={'btn-new primary br-8'} onClick={handleNewClick}>
              Add new
            </Button>
          </div>
        </div>

        <div className="mt-20px">{handleComponent()}</div>
      </div>

      {addSkillModal && <SkillModal />}
      {addGradeModal && <GradeModal />}
      {addRelationModal && <RelationModal />}
    </AppLayout>
  )
}

export default SpecificListingPage
