import React, { useState } from 'react'
import { Avatar, Col, Form, Input, Row } from 'antd'
import FileUploader from 'helper/FileUploader'

const SkillForm: React.FC = () => {
  const [form] = Form.useForm()
  const [avatar, setAvatar] = useState<string>('')

  const formSchema = {}

  const onFinish = (values: any) => {}

  return (
    <div>
      <Form
        name="skill-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row gutter={24} align="middle">
          <Col span={4} className="mb-10px">
            <Avatar size={80} src={avatar ? avatar : ''}></Avatar>
          </Col>
          <Col span={18} className="">
            <Form.Item name="photoId">
              <FileUploader
                setAvatar={setAvatar}
                name="icon"
                form={form}
                // fileId={
                //   adultData && adultData?.photoId
                //     ? adultData.photoId
                //     : undefined
                // }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-10px">
          <Col span={24}>
            <label className="input-label required-input">Name</label>
            <Form.Item
              colon={false}
              name="name"
              rules={[{ required: true, message: 'Name required' }]}
            >
              <Input placeholder="Enter Name" required name="name" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="mt-10px">
          <Col span={12}>
            <label className="input-label required-input">Price</label>
            <Form.Item
              colon={false}
              name="price"
              rules={[{ required: true, message: 'Price is required' }]}
            >
              <Input
                placeholder="Enter Price"
                type="number"
                required
                name="price"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <label className="input-label required-input">Color</label>
            <Form.Item
              colon={false}
              name="color"
              rules={[{ required: true, message: 'Color is required' }]}
            >
              <Input placeholder="Enter color code" required name="color" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default SkillForm
