import CustomModal from 'components/global/CustomModal'
import React from 'react'
import { skillCreateStore } from 'state/listing/skillListing'
import SkillForm from './ModalContent/SkillForm'

const SkillModal: React.FC = () => {
  const { addSkillModal, setAddSkillModal } = skillCreateStore(store => ({
    addSkillModal: store.addSkillModal,
    setAddSkillModal: store.setAddSkillModal,
  }))

  return (
    <>
      <CustomModal
        openModal={addSkillModal}
        closeModal={setAddSkillModal}
        title="Add new skill"
        footer={false}
      >
        <SkillForm />
      </CustomModal>
    </>
  )
}

export default SkillModal
