import React from 'react'
import { useHistory } from 'react-router'
import { Layout } from 'antd'
import 'antd/dist/antd.css'
import HeaderComponent from '../Header/HeaderComponent'
import DefaultSidebar from './DefaultSidebar'
import ProfilesSidebar from './ProfilesSidebar'
import DefaultSidebarV2 from './DefaultSidebarV2'
import EventSidebar from './EventSidebar'
import BackSideBar from './BackSidebar'
import LeagueSidebar from './LeagueSidebar'

const NavBar = ({ children, id }: any) => {
  const history = useHistory()

  const renderSwitch = (param: any) => {
    switch (param) {
      case `/organization-profile/${id}`:
        return (
          <Layout>
            <ProfilesSidebar />
            <Layout className="default-layout">{children}</Layout>
          </Layout>
        )
      case `/organizer-profile/${id}`:
        return (
          <Layout>
            <ProfilesSidebar />
            <Layout className="default-layout">{children}</Layout>
          </Layout>
        )
      case '/family-profile':
        return (
          <Layout>
            <ProfilesSidebar />
            <Layout className="profile-layout">{children}</Layout>
          </Layout>
        )
      case `/family-profile/${id}`:
        return (
          <Layout>
            <ProfilesSidebar />
            <Layout className="profile-layout">{children}</Layout>
          </Layout>
        )
      case `/parent-profile/${id}`:
        return (
          <Layout>
            <ProfilesSidebar />
            {children}
          </Layout>
        )
      case `/sessiondetails`:
        return (
          <Layout>
            <ProfilesSidebar />
            {children}
          </Layout>
        )
      case `/Jobs`:
        return (
          <Layout>
            <ProfilesSidebar />
            {children}
          </Layout>
        )
      case '/kid-details':
        return (
          <Layout>
            <ProfilesSidebar />
            <Layout className="profile-layout">{children}</Layout>
          </Layout>
        )
      case '/kid-profile':
        return (
          <Layout>
            <ProfilesSidebar />
            {children}
          </Layout>
        )
      case `/kid-profile/${id}`:
        return (
          <Layout>
            <ProfilesSidebar />
            {children}
          </Layout>
        )
      case '/coach-profile':
        return (
          <Layout>
            <ProfilesSidebar />
            {children}
          </Layout>
        )
      case `/events/create-event`:
        return (
          <Layout className="event-layout">
            <EventSidebar />
            {children}
          </Layout>
        )
      case `/events/update-event/${id}`:
        return (
          <Layout className="event-layout">
            <EventSidebar />
            {children}
          </Layout>
        )
      case `/events/create-league`:
        return (
          <Layout className="event-layout">
            <LeagueSidebar />
            {children}
          </Layout>
        )
      case `/events/update-league/${id}`:
        return (
          <Layout className="event-layout">
            <LeagueSidebar />
            {children}
          </Layout>
        )
      case '/events-details':
        return (
          <Layout>
            <ProfilesSidebar />
            {children}
          </Layout>
        )
      case '/event-chats':
        return (
          <Layout>
            <ProfilesSidebar />
            {children}
          </Layout>
        )

      case '/proposed-mentors':
        return (
          <Layout>
            <ProfilesSidebar />
            {children}
          </Layout>
        )
      case '/RequestDetails':
        return (
          <Layout>
            <BackSideBar link={'/RequestDetails'} />
            {children}
          </Layout>
        )
      case `/mentor-profile/${id}`:
        return (
          <Layout>
            <ProfilesSidebar />
            {children}
          </Layout>
        )
      case `/manage-listing/${id}`:
        return (
          <Layout>
            <BackSideBar link={'/settings'} />
            {children}
          </Layout>
        )
      default:
        return (
          <Layout>
            <DefaultSidebarV2 />
            <Layout>{children}</Layout>
          </Layout>
        )
    }
  }
  return (
    <Layout>
      <HeaderComponent />
      {renderSwitch(history.location.pathname)}
    </Layout>
  )
}

export default NavBar
