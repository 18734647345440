import { useQuery } from '@tanstack/react-query'
import { axiosGet } from 'Redux/axiosHelper'
import { SkillListing } from 'types/listing/listings'

const getListings = async () => {
  const response = await axiosGet(`v1/skill`)
  return response.data
}
export const useGetListings = () => {
  const listings = useQuery<SkillListing[]>(
    [`all-skill-listings`],
    getListings,
    { enabled: false },
  )
  return listings
}
